import React from 'react'
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

export const query = graphql`
  {
    allContentfulAuthor {
      edges {
        node {
          name
          profilePicture {
            id
            gatsbyImageData(width: 200, placeholder: BLURRED, formats: AUTO, quality: 100)
          }
        }
      }
    }
  }
`

const authors = ({data}) => {

  return (
    <div>
      Authors
      <ul>
      {data.allContentfulAuthor.edges.map(({node}) => (
        <li key={node.id}>
          {node.name}
          <GatsbyImage image={getImage(node.profilePicture)} />
        </li>
      ))}
      </ul>
    </div>
  )
}

export default authors
